import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import { ContactForm, JoinUs } from '../components';
import { MessageIcon } from '../components/icons';
import theme from '../styles/theme';
import WhatsApp from '@material-ui/icons/WhatsApp';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const Line = styled.div`
  height: 1px;
  width: 90%;
  background-color: ${theme.colors.grey2};
  margin: 60px 0 20px 0;
`;

const TitleLine = styled.div`
  height: 3px;
  width: 60px;
  background-color: ${theme.colors.primary};
  margin: 0 0 40px 0;
`;

const Title = styled.h4`
  font-size: ${theme.fontSizeLg * 0.9}px;
  font-weight: bold;
  margin: 40px 0 15px 0;
`;

const ItemTitle = styled.h4`
  font-size: ${theme.fontSize * 0.94}px;
  font-weight: bold;
  color: ${theme.colors.text_heading};
  margin: 0 0 20px 0;
`;

const Item = styled.p`
  font-size: ${theme.fontSizeLg * 0.8}px;
  color: ${theme.colors.grey3};
  margin: 0 0 15px 5px;
`;

const StyledIcon = styled(MessageIcon)`
  padding: 0.2em 0 0 0;
`;

const StyledContainer = styled.div`
  display: flex;
  width: 90%;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.3em;
`;

const ContactPage = () => {
  return (
    <Wrapper>
      <Title>FALE CONOSCO / OUVIDORIA</Title>
      <TitleLine />
      <ContactForm />
      <ItemTitle>Rede Vestidas de Branco</ItemTitle>
      <RowContainer>
        <WhatsApp fontSize="small" style={{ color: '#EFC874' }} />
        <Item>(31) 98823-0342 (Whatsapp)</Item>
      </RowContainer>
      <RowContainer>
        <StyledIcon />
        <Item>contato@vestidasdebranco.com.br</Item>
      </RowContainer>
      <Line />
      <StyledContainer>
        <JoinUs />
      </StyledContainer>
    </Wrapper>
  );
};

export default ContactPage;

export const pageQuery = graphql`
  query ContactQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
